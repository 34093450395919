import * as React from 'react';

export const boldLink = (text: any, link: any) => {
    return <b>
        <a
            href={link}
            target={"_blank"}
            rel={"noreferrer noopener"}
        >{text}</a>
    </b>
}