import * as React from 'react';
import "@fontsource/urbanist";

/** Components */
import AppRouter from '../Navigation/Routes';

/** Ant design stylesheet */
import 'antd/dist/antd.css';
import {boldLink} from "../Utils/HtmlUtils";

const App: React.FC = () => {

    return <div
        style={{
            fontFamily: "Urbanist"
        }}
    >
        {/*<AppRouter/>;*/}
        {/*    SHUTTING DOWN THE PROJECT....*/}

        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                flexDirection: "column"
            }}
        >
            <h1>Project is shutting down...</h1>
            <h2>For business inquiries, email

                {boldLink(
                    ' tom@ecomtrendfinder.com',
                    'mailto:tom@ecomtrendfinder.com'
                )}

            </h2>
        </div>
    </div>

};

export default App;
